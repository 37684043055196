<template>
  <div class="wrap">
    <div class="filter">
      <el-input
        style="width: 450px"
        placeholder="请输入对应的筛选"
        v-model="search_str"
        class="input-with-select"
      >
        <el-select
          v-model="select_index"
          style="width: 150px"
          slot="prepend"
          placeholder="请选择查询方式"
        >
          <el-option
            v-for="(item, index) in search_sel"
            :key="index"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-button
          @click="search"
          slot="append"
          icon="el-icon-search"
        ></el-button>
      </el-input>


      <el-button
          @click="delete_zhifu" 
        >删除一个月前未支付的订单记录</el-button>
    </div>

    <el-table :data="tableData" style="width: 100%">
      <el-table-column
        v-for="(item, index) in tableData_cell"
        :key="index"
        :prop="item.value"
        :label="item.title"
      ></el-table-column>
      
    </el-table>

     

    <div class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="pageTotal"
        :page-size="20"
        :current-page="pageNow"
        @current-change="pageNowHandler"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { search_zhifu,delete_zhifu } from "@/api/hr24_api/api_all.js";
import { showCity } from "@/api/resume";
import axios from "axios";

export default {
  data() {
    return {
      search_str: "",
      search_sel: [
        { label: "用户", value: "username" },
        { label: "公司", value: "license_company_name" },
      ],
      select_index: "",
      // 表单数据处理
      tableData_cell: [
        { title: "公司账号", value: "username" },
        { title: "公司名称", value: "company_name" },
        { title: "订单号", value: "uid" },
        { title: "标题", value: "title" },
        { title: "交易金额", value: "money" },
        { title: "openid", value: "openid" },
        { title: "状态", value: "state" },
        { title: "操作时间", value: "addtime" },
      ],
      tableData: [],
      // 编辑
      indry: [],
      city: [],
      is_seed: false,
      dialogFormVisible: false,
      formamend: {
        username: "",
        contact_name: "",
        license_company_name: [],
      },
      place_data: [],
      industry_name: "",
      user_detail_id: "",
      form: {},
      activeName: "essentialInformation",

      //总页码
      pageTotal: null,
      pageNow: 1,
    };
  },
  mounted() {
    // axios.get("/static/industry.json").then((res) => {
    //   this.indry = res;
    // });
    // showCity({}).then((res) => {
    //   console.log("dddddddddd showCity");
    //   this.city = res.data;
    // });

    this.pageNow = 1;
    let search_data = {};
    search_zhifu({ search_data, page: this.pageNow }).then((res) => {
      if (!res.code) {
        console.log("ssssssssssss");
        console.log(res.data);

        if (res.data.length) {
          res.data.map((item) => {
            console.log(item);
            item.state = item.state == 1 ? "已付款" : "未付款";
          });
        }
        this.pageTotal = res.all_count;
        this.tableData = res.data;
        this.$message.success(res.msg);
      } else {
        this.$message.error(res.msg);
      }
      console.log(res);
    });
  },
  methods: {
    delete_zhifu(){

      return this.$confirm('是否确定删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          delete_zhifu({ }).then((res) => {
          if (!res.code) {
            
            this.$message.success(res.msg);
             this.search(1);


          } else {
            this.$message.error(res.msg);
       
          }
          console.log(res);
        });


        })
        .catch((e) => {
          this.$message({
            type: 'info',
            message: '已取消',
          })
          // return Promise.reject()
        })


      
    },
    pageNowHandler(page) {
      console.log("pageNowHandler", page);

      if (this.pageNow != page) {
        this.loading = true;

        this.pageNow = page;

        this.search(1);
      }
    },

    getTime(date) {
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + m + "-" + d;
    },
    onSubmit() {
      console.log("dddddddddd onSubmit");
      let obj = {
        company_user: this.search_str,
        vip_end_time: this.getTime(this.form.date),
        is_seed: this.form.is_seed == true ? 1 : 0,
      };

      amend_date(obj).then((res) => {
        console.log("dddddddddd amend_date");
        if (!res.code) {
          this.dialogFormVisible = false;
          this.$message.success(res.msg);
          this.search(1);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleClick(val) {
      console.log(val);
    },
    areaChange(e) {
      console.log(e);
    },
    indusChange(e) {
      console.log(e);
    },
    search(e) {
      console.log("dddddddddd search");

      if (!this.search_str || !this.select_index) {
        let search_data = {};
        search_zhifu({ search_data, page: this.pageNow }).then((res) => {
          if (!res.code) {
            console.log("ssssssssssss");
            console.log(res.data);

            if (res.data.length) {
              res.data.map((item) => {
                console.log(item);
                item.state = item.state == 1 ? "已付款" : "未付款";
              });
            }
            this.pageTotal = res.all_count;
            this.tableData = res.data;
            this.$message.success(res.msg);
          } else {
            this.$message.error(res.msg);
            this.pageTotal = 0;
            this.tableData = [];
          }
          console.log(res);
        });
      }

      if (this.select_index && this.search_str) {
        let search_data = {
          [this.select_index]: this.search_str,
        };
        this.pageNow = 1;
        search_zhifu({ search_data, page: this.pageNow }).then((res) => {
          if (!res.code) {
            console.log("ssssssssssss");
            console.log(res.data);

            if (res.data.length) {
              res.data.map((item) => {
                console.log(item);
                item.state = item.state == 1 ? "已付款" : "未付款";
              });
            }
            this.pageTotal = res.all_count;
            this.tableData = res.data;
            this.$message.success(res.msg);
          } else {
            this.$message.error(res.msg);
            this.pageTotal = 0;
            this.tableData = [];
          }
          console.log(res);
        });
      }
    },

    redact(e) {
      console.log("sssssssssssss");
      this.activeName = "essentialInformation";
      this.dialogFormVisible = true;
      this.user_detail_id = e.user_detail_id;
      this.formamend = {
        username: e.username,
        company_name: e.company_name,
        integral: e.integral,
        type: e.type,
        yuan_jifen: e.yuan_jifen,
        jifen: e.jifen,
        remarks: e.remarks,
        create_time: e.create_time,
      };
    },

    amend() {
      console.log("dddddddddd");
      console.log(this.is_seed);

      if (this.activeName == "essentialInformation") {
        this.formamend;
        this.formamend.province_id = this.place_data[0];
        this.formamend.city_id = this.place_data[1];
        this.formamend.district_id = this.place_data[2];

        this.formamend.industry_id = this.industry_name[1];

        let update_data = JSON.stringify(this.formamend);

        let obj = {
          user_detail_id: this.user_detail_id,
          update_data,
        };
        amend_user(obj).then((res) => {
          if (!res.code) {
            this.dialogFormVisible = false;
            this.$message.success(res.msg);
            this.search(1);
          } else {
            this.$message.error(res.msg);
          }
        });
        return false;
      } else if (this.activeName == "MemberExpiration") {
        this.onSubmit();
        return false;
      } else {
      }
    },
  },
};
</script>

<style scoped>
.pagination {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.wrap {
  padding-top: 54px;
}
.filter {
  margin-bottom: 10px;
}
.wrap_box1 {
  display: flex;
}

.el-input {
  width: 30%;
  margin-right: 10px;
}
.el-select > .el-input {
  width: 100px !important;
}
.el-input__inner {
  width: 100px !important;
}
</style>
